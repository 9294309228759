/* eslint-disable no-param-reassign */
import { CreateEmployeeMessage } from "@frontend/common/src/types/EmployeeMessage";
import { Page } from "@frontend/common/src/types/Page";
import { UpdateCustomerEmployee } from "@frontend/common/src/types/CustomerEmployee";
import { Customer } from "@frontend/common/src/types/Customer";
import { UserType } from "@frontend/common/src/types/User";

import { Context } from ".";
import { getNavigationRoute } from "../app/pages";
import { mapToBackend } from "../helpers/ExtendedPrecutHelper";
import { ExtendedPrecut } from "../types/DraftOrder/ExtendedPrecut";
// import { OnInitialize } from "./index";

export const onContactPerson = async (
  { actions, effects, state }: any,
  createEmployeeMessage: CreateEmployeeMessage
) => {
  const message = await actions.modals.sendMessageModal.open({
    userId: createEmployeeMessage.employeeId,
  });
  createEmployeeMessage.loggedInEmail = state.auth.user.username;
  if (message && message.length) {
    createEmployeeMessage.message = message;
    try {
      await effects.api.employees.sendMessage(
        createEmployeeMessage,
        state.customer.id
      );
      effects.toast("Melding sendt", 5000);
    } catch (error) {
      effects.toast(error, 5000);
    }
  }
};
export const emulateEmployee = async (
  { state, effects }: Context,
  emulation: { customerId: string; customerEmployeeId: string }
) => {
  state.currentEmployeeId = emulation.customerEmployeeId;
  state.customer = await effects.api.customer.getById(emulation.customerId);
  state.customerEmployees =
    await effects.api.customerEmployee.getAllForCustomerId(
      emulation.customerId
    );
  state.auth.user.emulatedType = UserType.COMPANY;
};

export const emulateCustomer = async (
  { state, effects }: Context,
  customerId: string
) => {
  state.customer = await effects.api.customer.getById(customerId);
  state.customerEmployees =
    await effects.api.customerEmployee.getAllForCustomerId(customerId);
  state.auth.user.emulatedType = UserType.COMPANY;
};
export const onEditEmployee = async (
  { actions }: Context,
  employeeId: string
) => {
  actions.modals.editEmployeeModal.open({ employeeId });
};
export const onPrecutForm = async (
  { actions }: Context,
  formInfo: { projectId: string; openedFromProjectPage: boolean }
) => {
  const form = await actions.modals.precutModal.open({
    projectId: formInfo.projectId,
  });
  if (formInfo.openedFromProjectPage) {
    if (form !== null) {
      console.log(mapToBackend(form as ExtendedPrecut[]));
    }

    // post directly
  } else if (form !== null) {
    console.log(mapToBackend(form as ExtendedPrecut[]));
  }
};

export const promptFileUpload = async (
  { actions, effects, state }: any,
  projectId: string
) => {
  const files = await actions.modals.fileUploadModal.open({});
  if (files && files.length) {
    try {
      await effects.api.order.addFiles(projectId, files, state.customer.id);
      effects.toast("Filer er lastet opp", 5000);
      const docs = await effects.api.order.getDocumentsForOrder(
        state.customer.id,
        projectId
      );
      if (state.orders.currentOrder !== undefined) {
        state.orders.currentOrder.documents = docs;
      } else {
        state.orders.currentOrderProject.documents = docs;
      }
    } catch (error) {
      effects.toast("Feil ved opplasting", 5000);
    }
  }
};

export const openSelectionGridModal = async (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { actions, effects, state }: Context,
  params: { items: any[]; onSelected: (item: any) => void }
) => {
  const { items, onSelected } = params;
  const selectedItem = await actions.modals.selectionGridModal.open({ items });

  if (selectedItem && onSelected) {
    onSelected(selectedItem);
  }
};

export const changeUserActive = async (
  { state, effects }: Context,
  params: { userId: string; activeState: boolean }
) => {
  const employee = state.customerEmployees.find((c) => c.id === params.userId);
  employee.active = params.activeState;
  try {
    await effects.api.customerEmployee.update(
      state.customer.id,
      {
        ...employee,
      },
      state.auth.user.username
    );
    state.customerEmployees =
      await effects.api.customerEmployee.getAllForCustomerId(state.customer.id);
  } catch (errorMessage: any) {
    effects.toast(errorMessage, 5000);
  }
};

export const changeCustomer = async (
  { state, effects }: Context,
  customer: Customer
) => {
  try {
    await effects.api.customer.updateCustomer(customer);

    state.customer = {
      ...state.customer,
      ...customer,
    };
    effects.toast("Lagret", 5000);
  } catch (errorMessage: any) {
    effects.toast(errorMessage, 5000);
  }
};

export const changeCustomerEmployee = async (
  { state, effects }: Context,
  customerEmployee: UpdateCustomerEmployee
) => {
  try {
    await effects.api.customerEmployee.update(
      state.customer.id,
      customerEmployee,
      state.auth.user.username
    );
    state.customerEmployees =
      await effects.api.customerEmployee.getAllForCustomerId(state.customer.id);
    effects.toast("Lagret", 5000);
  } catch (errorMessage: any) {
    effects.toast(errorMessage, 5000);
  }
};

export const createCustomerEmployee = async (
  { state, effects }: Context,
  customerEmployee: UpdateCustomerEmployee
) => {
  try {
    await effects.api.customerEmployee.create(
      state.customer.id,
      customerEmployee
    );
    state.customerEmployees =
      await effects.api.customerEmployee.getAllForCustomerId(state.customer.id);
    effects.toast("Opprettet!", 5000);
  } catch (errorMessage: any) {
    effects.toast(errorMessage, 5000);
  }
};

export const onInitializeOvermind = async ({
  state,
  actions,
  effects,
}: Context) => {
  const cms = await effects.cms.fetch(
    "https://www.pretre.no/kundeportal/?xml=document"
  );
  state.cms = cms;

  state.isInitialLoadComplete = true;

  const { router } = effects;

  router.route(getNavigationRoute(Page.HOME), actions.navigation.openHome);
  router.route(
    getNavigationRoute(Page.TECHNICAL_SERVICES),
    actions.navigation.openTechnicalServices
  );
  router.route(getNavigationRoute(Page.LOGIN), actions.navigation.openLogin);
  router.route(
    getNavigationRoute(Page.RESET_PASSWORD),
    actions.navigation.openForgotPassword
  );

  router.route(
    getNavigationRoute(Page.ORDER_LIST),
    actions.navigation.openOrderList
  );
  router.route(
    getNavigationRoute(Page.VIEW_ORDER),
    actions.navigation.openViewOrder
  );
  router.route(
    getNavigationRoute(Page.VIEW_ORDER_PROJECT),
    actions.navigation.openViewOrderProject
  );

  router.route(
    getNavigationRoute(Page.CREATE_ORDER),
    actions.navigation.openCreateOrderStep
  );
  router.route(getNavigationRoute(Page.USER), actions.navigation.openUser);
  router.route(
    getNavigationRoute(Page.COMPANY),
    actions.navigation.openCompanyPage
  );
  router.route(
    getNavigationRoute(Page.DOCUMENTATION),
    actions.navigation.openDocumentationPage
  );
  router.route(getNavigationRoute(Page.EMULATE), actions.navigation.emulate);
  router.route(getNavigationRoute(Page.AUTH), actions.navigation.auth);
  router.route(
    getNavigationRoute(Page.CUSTOMER_SELECTOR),
    actions.navigation.openCompanySelector
  );
  try {
    router.start();
  } catch (error) {
    console.error(error);
  }
};
