import React, { FunctionComponent, useEffect, useState } from "react";
import { Page } from "@frontend/common/src/types/Page";
import { useAuth0 } from "@auth0/auth0-react";
import { useActions, useAppState, useEffects } from "../overmind";
import "./App.css";
import { GuestLayout } from "./layouts/GuestLayout/GuestLayout";
import { MainLayout } from "./layouts/MainLayout/MainLayout";
import { pages, PageAccess, createUrl } from "./pages";

export const App: FunctionComponent = () => {
  const state = useAppState();
  const effects = useEffects();
  const actions = useActions();
  const { isLoading, isAuthenticated, error, user, getAccessTokenSilently } =
    useAuth0();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (isAuthenticated && state.auth.user === null) {
      setLoading(true);
      getAccessTokenSilently().then((r) => {
        actions.auth
          .onMesterIdLoggedIn({ token: r, username: user.email })
          .then(() => setLoading(false));
      });
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, [isAuthenticated]);

  if (isLoading || loading) {
    return <div>Laster</div>;
  }
  if (error) {
    return <div>Oops... {error.message}</div>;
  }
  let CurrentPage = null;

  if (!pages[state.navigation.currentPage]) {
    return <div>404</div>;
  }

  CurrentPage = pages[state.navigation.currentPage];

  if (!CurrentPage) {
    return <div>404</div>;
  }

  const CurrentPageComponent = CurrentPage.component;

  if (!isAuthenticated && CurrentPage.restrictedTo === PageAccess.AUTH) {
    effects.toast("Du må logge inn for å se denne siden");
    effects.router.navigateTo(createUrl(Page.HOME));
  } else if (
    !isAuthenticated &&
    CurrentPage.restrictedTo === PageAccess.GUEST
  ) {
    effects.router.navigateTo(createUrl(Page.HOME));
  }

  if (isAuthenticated) {
    return (
      <MainLayout currentPage={state.navigation.currentPage}>
        <CurrentPageComponent />
      </MainLayout>
    );
  }
  return (
    <GuestLayout currentPage={state.navigation.currentPage}>
      <CurrentPageComponent />
    </GuestLayout>
  );
};
