import React, { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Page } from "@frontend/common/src/types/Page";
import { CMSArticles } from "@frontend/common/src/types/CMSArticle";
import { CMSContent } from "../../../components/CMSContent/CMSContent";
import { RoofForm } from "./RoofForm";
import {
  BlockButton,
  ChooseProductPageButton,
  LinkButton,
} from "../../../components/Buttons/Buttons.styles";
import { createUrl } from "../../../app/pages";
import { useActions, useAppState, useEffects } from "../../../overmind";
import {
  ButtonWrapper,
  ChooseProductPageMainContainer,
  PageFlexConstraint,
} from "./CreateOrderPage.styles";

import { SubProjects } from "./SubProjects";
import { JoistsForm } from "./JointsForm";
import { PrecutForm } from "./PrecutForm";
import { DraftOrderSubProject } from "../../../types/DraftOrder/DraftOrderSubProject";
import { TechnicalServicesForm } from "./TechnicalServicesForm";
import { Header } from "../../../elements/Header/Header";
import { ExtendedRoofForm } from "./ExtendedRoofForm";
import { ExtendedPrecutForm } from "./ExtendedPrecutForm";
import { ExtendedBeamLayerForm } from "./ExtendedBeamLayerForm";

export const ChooseProductPage: FunctionComponent = () => {
  const effects = useEffects();
  const actions = useActions();
  const { t } = useTranslation();
  const { orders } = useAppState();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [subProjects, setSubProjects] = useState<DraftOrderSubProject[]>([]);
  const [selectedSubProject, setSelectedSubProject] = useState<
    DraftOrderSubProject | undefined
  >(undefined);
  const [activeForm, setActiveForm] = useState<string>("");
  useEffect(() => {
    if (orders.quickChoice !== null) {
      setActiveForm(orders.quickChoice);
    }
  }, [orders.quickChoice]);
  useEffect(() => {
    if (orders.currentNewOrder.subProjects) {
      setSubProjects(orders.currentNewOrder.subProjects);
      if (
        orders.quickChoice !== undefined &&
        orders.currentNewOrder.subProjects.length > 0 &&
        orders.autofill !== null
      ) {
        setSelectedSubProject(orders.currentNewOrder.subProjects[0]);
      }
    } else if (orders.autofill?.subProjects !== undefined) {
      setSubProjects(orders.autofill.subProjects);
    }
  }, [orders.currentNewOrder.subProjects, orders.autofill]);

  const onSubmit = () => {
    actions.orders.setSubProjectsOnDraftOrder(subProjects);
    effects.router.navigateTo(
      createUrl(Page.CREATE_ORDER, { slug: "vedlegg" })
    );
  };
  useEffect(() => {
    if (
      subProjects.length === 1 &&
      orders.quickChoice !== undefined &&
      activeForm.length === 0
    ) {
      onSubmit();
    }
  }, [subProjects, activeForm]);
  const onBackButtonClicked = () => {
    actions.orders.setSubProjectsOnDraftOrder(subProjects);
    effects.router.navigateTo(
      createUrl(Page.CREATE_ORDER, { slug: "kundeinfo" })
    );
  };
  const onFormButtonClick = (form: string) => {
    setActiveForm(form);
    setSelectedSubProject(undefined);
  };
  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <PageFlexConstraint>
        <div
          style={{
            width: "100%",
          }}
        >
          <Header type="secondary">4. Produktvalg</Header>
          <CMSContent contentId={CMSArticles.ORDER_SELECTION_HELPER} />
        </div>
      </PageFlexConstraint>

      <ChooseProductPageMainContainer>
        {orders.quickChoice !== undefined ? null : (
          <SubProjects
            selectedSubProject={selectedSubProject}
            setSelectedSubProject={setSelectedSubProject}
            subProjects={subProjects}
            setSubProjects={setSubProjects}
            setActiveForm={setActiveForm}
            autoFill={orders.autofill?.offerId !== undefined}
          />
        )}
        <div style={{ width: "100%" }}>
          {orders.quickChoice !== undefined ||
          (activeForm !== "" && activeForm !== undefined) ? null : (
            <ButtonWrapper>
              {orders.isQuote ? (
                <ChooseProductPageButton
                  onClick={() => onFormButtonClick("extendedRoof")}
                  active={false}
                  disabled={orders.autofill?.offerId !== undefined}
                  extended
                >
                  {t("AddRoof")}
                </ChooseProductPageButton>
              ) : (
                <ChooseProductPageButton
                  onClick={() => onFormButtonClick("roof")}
                  active={false}
                  disabled={orders.autofill?.offerId !== undefined}
                >
                  {t("AddRoof")}
                </ChooseProductPageButton>
              )}

              <ChooseProductPageButton
                onClick={() => onFormButtonClick("beamlayer")}
                active={false}
                disabled={orders.autofill?.offerId !== undefined}
              >
                {t("AddBeamlayer")}
              </ChooseProductPageButton>

              <ChooseProductPageButton
                onClick={() => onFormButtonClick("precut")}
                active={false}
                disabled={orders.autofill?.offerId !== undefined}
              >
                {t("AddPrecut")}
              </ChooseProductPageButton>
              <ChooseProductPageButton
                onClick={() => onFormButtonClick("technical")}
                active={false}
                disabled={orders.autofill?.offerId !== undefined}
              >
                {t("AddTechnical")}
              </ChooseProductPageButton>
            </ButtonWrapper>
          )}
          {activeForm === "roof" && (
            <RoofForm
              setSelectedSubProject={setSelectedSubProject}
              selectedSubProject={selectedSubProject}
              setActiveForm={setActiveForm}
              setSubProjects={setSubProjects}
              subProjects={subProjects}
              autoFill={orders.autofill?.offerId !== undefined}
            />
          )}
          {activeForm === "extendedRoof" && (
            <ExtendedRoofForm
              setSelectedSubProject={setSelectedSubProject}
              selectedSubProject={selectedSubProject}
              setActiveForm={setActiveForm}
              setSubProjects={setSubProjects}
              subProjects={subProjects}
              autoFill={orders.autofill?.offerId !== undefined}
              quickChoice={orders.quickChoice}
              onMainCancel={onBackButtonClicked}
            />
          )}
          {activeForm === "beamlayer" && (
            <JoistsForm
              setSelectedSubProject={setSelectedSubProject}
              selectedSubProject={selectedSubProject}
              setActiveForm={setActiveForm}
              setSubProjects={setSubProjects}
              subProjects={subProjects}
              autoFill={orders.autofill?.offerId !== undefined}
            />
          )}
          {activeForm === "extendedBeamlayer" && (
            <ExtendedBeamLayerForm
              setSelectedSubProject={setSelectedSubProject}
              selectedSubProject={selectedSubProject}
              setActiveForm={setActiveForm}
              setSubProjects={setSubProjects}
              subProjects={subProjects}
              autoFill={orders.autofill?.offerId !== undefined}
            />
          )}
          {activeForm === "precut" && (
            <PrecutForm
              setSelectedSubProject={setSelectedSubProject}
              selectedSubProject={selectedSubProject}
              setActiveForm={setActiveForm}
              setSubProjects={setSubProjects}
              subProjects={subProjects}
              autoFill={orders.autofill?.offerId !== undefined}
            />
          )}
          {activeForm === "extendedPrecut" && (
            <ExtendedPrecutForm
              setSelectedSubProject={setSelectedSubProject}
              selectedSubProject={selectedSubProject}
              setActiveForm={setActiveForm}
              setSubProjects={setSubProjects}
              subProjects={subProjects}
              autoFill={orders.autofill?.offerId !== undefined}
            />
          )}
          {activeForm === "technical" && (
            <TechnicalServicesForm
              setSelectedSubProject={setSelectedSubProject}
              selectedSubProject={selectedSubProject}
              setActiveForm={setActiveForm}
              setSubProjects={setSubProjects}
              subProjects={subProjects}
              autoFill={orders.autofill?.offerId !== undefined}
              quickChoice={orders.quickChoice}
              onMainCancel={onBackButtonClicked}
            />
          )}
        </div>
      </ChooseProductPageMainContainer>
      <div
        style={{
          display: activeForm ? "none" : "flex",
          justifyContent: "flex-end",
        }}
      >
        <div style={{ display: "flex", width: "30vw", marginTop: "2rem" }}>
          <BlockButton
            disabled={subProjects.length === 0}
            style={{ width: "1000px" }}
            onClick={() => onSubmit()}
          >
            Neste
          </BlockButton>
          <LinkButton as="a" onClick={onBackButtonClicked}>
            {t("Tilbake")}
          </LinkButton>
        </div>
      </div>
    </div>
  );
};
